import React from 'react'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import { Global, css } from "@emotion/react"

export default function About({ data }) {
  const {hardhatImage, jacksonImage} = data
  console.log(hardhatImage)
  return (
    <Layout logo='about'>
      <Global
        styles={css`
          * {
            background: #b3b68c;
            color: #756729;
          }
          nav a {
            color: #756729;
          }
        `}
      />
      <section>
        <div>
          <h1>About Page</h1>
          <p>Mad Studio is an architecture and interiors studio based out of Portland, Oregon. We strive to create beautiful spaces that are unique, creative, and even a little bit of fun. With  a  strong  emphasis  on  collaboration  and  communication,  each  project  is  an opportunity to discover something new while creating something beautiful. We take on a variety of projects from space planning and concept design, to new construction and construction coordination, to material and furniture selection</p>
          <p>Mad Studio is led by Madeline Hartzell, AIA, a licensed architect in the states  of  California  and  Wisconsin.  She  graduated  with  a  B.A. from  Columbia University  and  a M.Arch from University  of  California,  Berkeley. Her  professional   experiences includes over 5 years working for large-scale projects during her time at  Skidmore, Owings, & Merrill (SOM) to numerous fast-paced interior projects while at Rapt  Studio.  She  grew  up  on  a  small  island  in  Lake  Superior,  but  now  lives  in the Pacific Northwest with her husband and sweet dog Jackson.</p>
        </div>
      </section>
      <GatsbyImage image={ getImage(hardhatImage) } alt={ hardhatImage.name } />
      <GatsbyImage image={ getImage(jacksonImage) } alt={ jacksonImage.name } />
    </Layout>
  )
}

export const query = graphql`
  query AboutPage {
    hardhatImage: file(sourceInstanceName: {eq: "imagesMisc"}, name: {eq: "hardhat"}) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    jacksonImage: file(sourceInstanceName: {eq: "imagesMisc"}, name: {eq: "jackson"}) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
